<template>
	<v-container>
		<v-row>
			<v-col cols="6">
				<admin-default-header>Quizes</admin-default-header>
				<admin-default-description>
					Lorem, ipsum dolor sit amet consectetur adipisicing elit.
					Ipsam maiores vero quo nemo accusantium quae delectus a illo
					laudantium pariatur, soluta velit repellendus iusto deserunt
					perferendis odit, facere, magni veniam.
				</admin-default-description>
				<v-text-field
					v-model="searchText"
					class="search-course"
					placeholder="Start typing to Search"
					filled
					:loading="isLoading"
				></v-text-field>
			</v-col>
			<v-col cols="6"></v-col>
		</v-row>
		<v-tabs
			v-model="currentItem"
			ligth
			background-color="transparent"
			:slider-color="$store.getters.theme.mainAccentColor"
			slider-size="8"
			color="#000"
			class="organization-settings-tab-buttons-container"
		>
			<v-tab
				v-for="(tab, index) in tabs"
				:key="index"
				:href="`#${tab.component}`"
				class="organization-settings-tab-button primary-text"
				@click="currentItem = tab.component"
			>
				{{ tab.title }}
			</v-tab>
		</v-tabs>

		<v-tabs-items
			v-model="currentItem"
			class="organization-settings-tabs-container"
		>
			<v-tab-item
				v-for="tab in tabs"
				:key="tab.title"
				:value="tab.component"
				class="organization-settings-tab"
			>
				<keep-alive>
					<component :is="tab.component" />
				</keep-alive>
			</v-tab-item>
		</v-tabs-items>
	</v-container>
</template>

<script>
export default {
	name: "ResponseBank"
};
</script>

<style></style>
